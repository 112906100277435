import './scss/style.scss';

import React, { useEffect } from 'react';
import Header from "./components/Header.jsx";
import Sidebar from './components/Sidebar.jsx';
import Scoreboard from './components/Scoreboard.jsx';
import { BrowserRouter as Router, Route, Routes, Link, useParams, BrowserRouter } from 'react-router-dom';
import Sport from './components/Sport.jsx';
import Country from './components/Country.jsx';
import League from './components/League.jsx';
import Game from './components/Game.jsx';
import Match from './components/Match.jsx';
import { ScoreboardProvider } from './components/Scoreboard.jsx';
import { AppContextProvider } from './AppContext.jsx';
import Banner from './components/Banner.jsx';


function App() {

  return (
    <AppContextProvider>
      <ScoreboardProvider>
        <BrowserRouter>
          <Banner className="mobile-banner" />
          <Header />
          <Routes>
            <Route path="/" Component={Sport} />
            <Route path="/:sport" Component={Sport} />
            <Route path="/:sport/:country" Component={Country} />
            <Route path="/:sport/:country/:league" Component={League} />
            <Route path="/:sport/:country/:league/:match" Component={Game} />
            <Route path="/match/:matchid" Component={Match} />
          </Routes>
        </BrowserRouter>
      </ScoreboardProvider>
    </AppContextProvider>
  );
}

export default App;
