export const sportsTranslations = {
    "football": "Футбол",
    "hockey": "Хоккей",
    "basketball": "Баскетбол",
    "volleyball": "Волейбол",
    "tennis": "Теннис",
    "esports": "Киберспорт"
}
export const statusTranslations = {
    "1": "&nbsp;",
    "45": "Будет доигран позже",
    "42": "Ждем...",
    "2": "Live",
    "12": "1-й тайм",
    "13": "2-й тайм",
    "6": "Дополнительное время",
    "7": "Серия пенальти",
    "38": "Перерыв",
    "46": "Перерыв",
    "3": "Завершен",
    "10": "После дополнительного времени",
    "11": "После серии пенальти",
    "9": "Неявка",
    "43": "Задержка",
    "36": "Прерван",
    "4": "Перенесен",
    "5": "Отменен",
    "37": "Прерван",
    "54": "Тех. поражение"
};